import { styled, InputBase, Button, Box } from '@mui/material'
import { navigate } from 'gatsby'
import SearchIcon from '@mui/icons-material/Search'
import { debounce } from 'lodash'

const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: ${props => props.theme.spacing(2)} 0px
    ${props => props.theme.spacing(2)};
`

const SearchContainer = styled('div')`
  display: flex;
  align-items: center;
`

interface ParticipantsTableFilterProps {
  params: any
  setParams: (params: any) => void
  setModal: (show: boolean) => void
}

const ParticipantsTableFilter = (props: ParticipantsTableFilterProps) => {
  const { params, setParams, setModal } = props

  const onSearch = debounce((searchStr: string) => {
    setParams({ ...params, search: searchStr })
  }, 300)

  return (
    <Container>
      <SearchContainer>
        <div style={{ marginRight: '0.3rem' }}>
          <SearchIcon />
        </div>

        <InputBase
          placeholder="Search"
          inputProps={{ 'aria-label': 'search' }}
          onChange={e => onSearch(e.target.value)}
        />
      </SearchContainer>
      <Box display="flex" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setModal(true)
          }}
          style={{ fontSize: '0.8rem', marginRight: '1rem' }}
        >
          Player Transfer
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('create')
          }}
          style={{ fontSize: '0.8rem' }}
        >
          CREATE
        </Button>
      </Box>
    </Container>
  )
}

export default ParticipantsTableFilter
